import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Image,
  ActivityIndicator,
  View,
  Text,
  Dimensions,
  ScrollView,
  Pressable,
} from "react-native";
import { getPreciseDistance } from "geolib";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faHeart } from "@fortawesome/free-solid-svg-icons";
import { MapView } from "../components/MapView";

import { EventDate } from "../components/EventDate";
import { useTheme } from "../providers/ThemeContext";
import { restApi, getScaledImageUrl } from "../providers/restApi";
import { FollowButton } from "../components/FollowButton";
import { LinkPane } from "../components/LinkPane";
import { TranslatedText, TranslatedData } from "../components/TranslatedText";
import { SettingsContext } from "../providers/SettingsContext";
import { Carousel } from "react-responsive-carousel";

import { useTranslatedData } from "../providers/translate";

import { getNextEventDate } from "../utils";

import { HeaderContext } from "../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { Tag } from "../components/Tag";
import { FacilityLineItem } from "../components/FacilityLineItem";
import { OrganisationLineItem } from "../components/OrganisationLineItem";

const emptyImage = require("../assets/event.jpg");

const EventDetailsScreen = ({ onLocationChanged }) => {
  const { id: eventId } = useParams();

  const settingsContext = useContext(SettingsContext);
  const headerContext = useContext(HeaderContext);
  const navigate = useNavigate();

  const [event, setEvent] = useState({});
  const [showDateInterval, setShowDateInterval] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const theme = useTheme();
  const listRef = useRef();
  const [sliderIndex, setSliderIndex] = useState();
  const [autoSlide, setAutoSlide] = useState(true);
  const { width: windowWidth, height: windowHeight } = Dimensions.get("window");

  const locationPermissions = (settingsContext.state.permissions || {})
    .location;

  const [hasUserLocation, setHasUserLocation] = useState(false);
  const [userLocation, setUserLocation] = useState(undefined);

  const { translate } = useTranslatedData();

  useEffect(() => {
    if (eventId) refresh();
  }, [eventId]);

  const refresh = async () => {
    setIsLoading(true);
    let event = await restApi.get(`/events/${eventId}`);
    if (event.hasRecurringDate) {
      event.date = getNextEventDate(event);
    }
    try {
      const startDate = new Date(
        event.date.start.stringValue
      ).toLocaleDateString();
      const endDate = new Date(event.date.end.stringValue).toLocaleDateString();
      setShowDateInterval(startDate !== endDate);
    } catch (e) {
      console.warn(e);
    }
    setEvent(event);
    headerContext.setOptions({ visible: false, title: event.title });
    setIsLoading(false);
  };

  const scrollToIndex = (index) => {
    listRef &&
      listRef.current &&
      listRef.current.scrollToIndex({ index: index, animated: true });
  };

  const leftButtonIcon = (
    <FontAwesomeIcon icon={faArrowLeft} color={theme.colors.text} size={"xl"} />
  );

  useEffect(() => {
    (async () => {
      if (locationPermissions && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          setUserLocation({
            lon: position.coords.longitude,
            lat: position.coords.latitude,
          });
          setHasUserLocation(true);
          if (typeof onLocationChanged === "function")
            onLocationChanged(position);
        });
      }
    })();
  }, []);

  useEffect(() => {
    if (!autoSlide) return;
    const timer = setInterval(() => {
      const maxIndex = media.length;
      let nextIndex = 0;
      if (sliderIndex + 1 < maxIndex) nextIndex = sliderIndex + 1;
      scrollToIndex(nextIndex);
      setSliderIndex(nextIndex);
    }, 5000);
    return () => clearInterval(timer);
  }, [sliderIndex, autoSlide]);

  const cancelAutoSlide = (e) => {
    setAutoSlide(false);
  };

  const goBack = () => {
    navigate(-1);
  };

  const browseByTag = (tag) => {
    navigate(`/browse/${tag.id}`);
  };

  const navigateToOrganisation = (organisation) => {
    navigate(`/organisation/${organisation}`);
  };

  const navigateToFacility = (facility) => {
    navigate(`/facility/${facility}`);
  };

  const location = event.location;
  const distance =
    hasUserLocation && location
      ? getPreciseDistance(userLocation, location, 10)
      : 0;

  let media = event.media || [];
  if (media.length === 0) media = [{}];

  if (isLoading) {
    return (
      <>
        <View
          style={[
            theme.style.screenHeader,
            {
              backgroundColor: "transparent",
              position: "absolute",
              zIndex: 1,
              top: 10,
              left: 0,
            },
          ]}
        >
          <Pressable
            onPress={goBack}
            style={theme.style.screenHeaderLeftContainer}
          >
            {leftButtonIcon}
          </Pressable>
        </View>
        <View
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator size="large" color={theme.colors.text} />
        </View>
      </>
    );
  }

  const loadingIndicator = (
    <View
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <ActivityIndicator size="large" color={theme.colors.text} />
    </View>
  );

  return (
    <ScrollView style={{ backgroundColor: theme.colors.background }}>
      <View
        style={[
          theme.style.screenHeader,
          {
            backgroundColor: "transparent",
            position: "absolute",
            zIndex: 1,
            top: 10,
            left: 0,
            width: "100%",
          },
        ]}
      >
        <Pressable
          onPress={goBack}
          style={theme.style.screenHeaderLeftContainer}
        >
          {leftButtonIcon}
        </Pressable>
        <FollowButton
          type="event"
          id={eventId}
          followIcon={
            <FontAwesomeIcon
              icon={faHeart}
              size={"lg"}
              color={theme.colors.text}
              style={{ marginRight: 6 }}
            />
          }
          unFollowIcon={
            <FontAwesomeIcon
              icon={faHeart}
              size={"lg"}
              color={theme.colors.text}
              style={{ marginRight: 6 }}
            />
          }
          followTitle={<TranslatedText id="general.follow" />}
          unFollowTitle={<TranslatedText id="general.unfollow" />}
        />
      </View>

      <Carousel showIndicators={false} showStatus={false} showThumbs={false}>
        {media
          .map((m, imageIndex) => ({ key: `${event.id}-${imageIndex}`, ...m }))
          .map((item, index) => {
            const url = getScaledImageUrl(
              item,
              766,
              766,
              emptyImage
            );
            console.log(url);
            return (
              <div
                key={index}
                style={{
                  background: `url(${url.uri}) no-repeat top center`,
                  backgroundSize: "cover",
                  height: 766 / 2.5,
                  //width: windowWidth,
                  //height: windowHeight / 2.5,
                }}
              >
                {/*<Image
                  key={index}
                  source={getScaledImageUrl(
                    item,
                    windowWidth,
                    windowHeight / 2.5,
                    emptyImage
                  )}
                  PlaceholderContent={loadingIndicator}
                />
                */}
              </div>
            );
          })}
      </Carousel>

      <View
        style={[
          theme.style.content,
          {
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            flex: 1,
            marginTop: -20,
          },
        ]}
      >
        <View style={[theme.style.contentBlock]}>
          <View style={[{ flexDirection: "row" }]}>
            <View style={{ flexGrow: 1, flex: 1, marginRight: 10 }}>
              <TranslatedData
                style={[theme.style.defaultTitleText]}
                data={event}
                id="title"
              />
              <TranslatedData
                style={[theme.style.defaultIngressText, theme.style.marginFix]}
                data={event}
                id="tagLine"
              />
            </View>
            <View>
              <EventDate fullDate={event.date}>
                {event.date.start.stringValue}
              </EventDate>
              {hasUserLocation && location && (
                <Text
                  style={[theme.style.eventDistance, theme.style.dropShadow]}
                >
                  {(distance / 1000).toFixed(1)} km
                </Text>
              )}
            </View>
          </View>

          <div
            dangerouslySetInnerHTML={{
              __html: translate(event, "description"),
            }}
          ></div>

          {showDateInterval && (
            <Text style={[theme.style.marginFix]}>
              Pågår mellan:{" "}
              {new Date(event.date.start.intValue).toLocaleDateString()} -{" "}
              {new Date(event.date.end.intValue).toLocaleDateString()}
            </Text>
          )}
          {event.date.useStartTime && (
            <Text style={[theme.style.marginFix]}>
              <TranslatedText id="general.starts_at" />{" "}
              <Text style={[theme.style.defaultIngressText]}>
                {new Date(event.date.start.intValue)
                  .toTimeString()
                  .substring(0, 5)}
              </Text>
            </Text>
          )}

          {event.date.useEndTime && (
            <Text>
              <TranslatedText id="general.ends_at" />{" "}
              <Text style={[theme.style.defaultIngressText]}>
                {new Date(event.date.end.intValue)
                  .toTimeString()
                  .substring(0, 5)}
              </Text>
            </Text>
          )}

          <View style={[theme.style.marginFix]}>
            {event.tags && (
              <View
                style={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {(event.tags || []).map((tag, index) => {
                  return (
                    <Tag
                      key={index}
                      id={tag.id}
                      onPress={(e) => browseByTag(tag)}
                    />
                  );
                })}
              </View>
            )}
          </View>

          <View style={[theme.style.marginFix]}>
            {event.facilities &&
              event.facilities.map((facility, i) => {
                return (
                  <FacilityLineItem
                    key={i}
                    id={facility.id}
                    name={facility.name}
                    onPress={(e) => navigateToFacility(facility.id)}
                  />
                );
              })}

            {event.organisations &&
              event.organisations.map((organisation, i) => {
                return (
                  <OrganisationLineItem
                    key={i}
                    id={organisation.id}
                    name={organisation.name}
                    onPress={(e) => navigateToOrganisation(organisation.id)}
                  />
                );
              })}
          </View>

          <LinkPane item={event} />
        </View>

        {event.location && (
          <>
            <View style={[theme.style.contentBlock]}>
              <View style={[theme.style.content, theme.style.dropShadowIOS]}>
                <MapView
                  centerAt={{
                    longitude: event.location.lon,
                    latitude: event.location.lat,
                  }}
                  markers={[
                    {
                      longitude: event.location.lon,
                      latitude: event.location.lat,
                    },
                  ]}
                ></MapView>
              </View>
            </View>
          </>
        )}
      </View>
    </ScrollView>
  );
};

export { EventDetailsScreen };
